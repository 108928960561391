<app-section-header-gap
  *ngIf="mediaService.isLtXl$ | async"
></app-section-header-gap>
<div class="container">
  <app-banner-one class="container__banner"></app-banner-one>
  <lib-section-features
    class="container__features"
    [featuresData]="featuresData"
  ></lib-section-features>
  <div
    id="preform"
    class="container__title"
  >
    <h1
      class="title"
      [innerHTML]="book.caption | safe : 'html'"
    ></h1>
  </div>
  <div class="container__info">
    <div class="info">
      <div class="info__gallery">
        <lib-gallery [photos]="book.photos"></lib-gallery>
      </div>
      <div class="info__description">
        <div class="description">
          <div class="description__section">
            <lib-notice
              [alias]="book.alias"
              *ngIf="
                discountService.activeDiscountCampaign$
                  | async as discountCampaign;
                else noticeInactiveDiscountCampaigns
              "
              [caption]="book.notice.caption"
              [description]="book.notice.description"
              [points]="book.notice.points"
              [prices]="book.prices"
              [vaguePrice]="true"
              [discount]="getBooksDiscount(discountCampaign, book.alias)"
              buttonText="ギフトコード対応"
            ></lib-notice>

            <ng-template #noticeInactiveDiscountCampaigns>
              <lib-notice
                [alias]="book.alias"
                [caption]="book.notice.caption"
                [description]="book.notice.description"
                [points]="book.notice.points"
                [prices]="book.prices"
                [vaguePrice]="true"
                buttonText="ギフトコード対応"
              ></lib-notice>
            </ng-template>
          </div>
          <div
            *ngIf="!(mediaService.isLtXl$ | async) && book.review as review"
            class="description__section"
          >
            <ng-container
              *ngTemplateOutlet="reviewsTpl; context: { $implicit: review }"
            ></ng-container>
          </div>
          <div
            *ngIf="!(mediaService.isLtXl$ | async) && book.points?.length"
            class="description__section"
          >
            <lib-points [points]="$any(book.points)"></lib-points>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container__form">
    <lib-book-form
      [alias]="book.alias"
      [inputs]="book.inputs"
      (submitted)="submitted($event)"
    ></lib-book-form>
  </div>
  <div
    *ngIf="(mediaService.isLtXl$ | async) && book.review as review"
    class="container__review"
  >
    <ng-container
      *ngTemplateOutlet="reviewsTpl; context: { $implicit: review }"
    ></ng-container>
  </div>
  <div
    class="container__points"
    *ngIf="(mediaService.isLtXl$ | async) && book.points?.length"
  >
    <lib-points [points]="$any(book.points)"></lib-points>
  </div>
  <div class="container__date">
    <lib-section-message-date></lib-section-message-date>
  </div>
  <div class="container__description-banner">
    <app-section-description-banner></app-section-description-banner>
  </div>
  <div
    *ngIf="book.description"
    class="container__description"
  >
    <lib-description [description]="book.description"></lib-description>
  </div>
  <div
    id="reviews"
    class="container__reviews"
  >
    <lib-section-reviews-yotpo-book
      [alias]="book.alias"
      [caption]="book.caption"
      [prices]="book.prices"
      [photo]="book.previewPhoto"
      title="リアルタイムレビュー"
      [showMonster]="false"
    ></lib-section-reviews-yotpo-book>
    <!-- *ngIf="book.reviews" -->
    <!-- <lib-section-reviews [reviews]="book.reviews"></lib-section-reviews> -->
  </div>
  <div class="container__about-banner">
    <app-section-about-banner></app-section-about-banner>
  </div>
  <div class="container__steps">
    <lib-section-steps [steps]="sectionSteps"></lib-section-steps>
  </div>
  <div class="container__faq">
    <lib-section-faq
      [condensed]="true"
      [faqData]="faqDataCondensed"
      (buttonClick)="onFaqButtonClick()"
    ></lib-section-faq>
  </div>
  <div class="container__footer-banner">
    <app-section-footer-banner></app-section-footer-banner>
  </div>
</div>

<app-scroll-top></app-scroll-top>

<ng-template
  #reviewsTpl
  let-review
>
  <section class="reviews">
    <div class="reviews__content">
      <p
        *ngFor="let text of review.text"
        class="reviews-text"
      >
        {{ text }}
      </p>
    </div>
    <footer class="reviews__footer">
      <a
        href="#reviews"
        class="reviews-text"
        >レビューを読む</a
      >
      <img
        src="assets/images/reviews-stars.png"
        alt="★★★★★"
        class="reviews-stars"
      />
    </footer>
  </section>
</ng-template>
