<div class="container">
  <div class="container__title">
    <h1 class="title">電車の運転士になれる絵本</h1>
  </div>
  <div class="container__content">
    <p class="content">
      東京メトロの ”主人公になれる”
      <br
        *ngIf="mediaService.isLtLg$ | async"
      />オリジナル絵本。<br />電車が好きな子へ贈りたい、<br
        *ngIf="mediaService.isLtLg$ | async"
      />世界に一冊のオーダーメイド。
    </p>
  </div>
  <div class="container__button">
    <button
      class="button"
      (click)="onButtonClick()"
    >
      <span class="button__text">絵本を作る</span>
    </button>
  </div>
</div>
